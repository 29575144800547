import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import './menu-link.css';
import styles from './Header.module.scss';
import logodt from '../../assets/images/dt-filmes.png';
import iconsearch from '../../assets/images/icon-search.png';
import guara from '../../assets/images/guara.png';
import { Container, Row, Col } from 'react-grid-system';
import Button from "react-bootstrap/Button";/* 
import ModalNews from "../modal/ModalNews";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"; */
import { elastic as Menu } from 'react-burger-menu';
import Form from 'react-bootstrap/Form'

// Translation Higher Order Component
import {
    translate,
    setTranslations
} from 'react-switch-lang';

import en from '../lang/en.json';
import pt from '../lang/pt.json';

// Do this two lines only when setting up the application
setTranslations({ en, pt });

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toggleMenu: "",
            isOpen: false,
            searchValue: ""
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleMenuClick = this.toggleMenuClick.bind(this);
    }

    toggleMenuClick(e) {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: true });
        }
    }

    toggleMenu(e) {
        if (this.state.toggleMenu === "") {
            this.setState({ toggleMenu: "menu_active", isOpen: true });
        } else {
            this.setState({ toggleMenu: "", isOpen: false });
        }
    }

    removerAcentos(newStringComAcento) {
        var string = newStringComAcento;
        var mapaAcentosHex = {
            a: /[\xE0-\xE6]/g,
            e: /[\xE8-\xEB]/g,
            i: /[\xEC-\xEF]/g,
            o: /[\xF2-\xF6]/g,
            u: /[\xF9-\xFC]/g,
            c: /\xE7/g,
            n: /\xF1/g
        };

        for (var letra in mapaAcentosHex) {
            var expressaoRegular = mapaAcentosHex[letra];
            string = string.replace(expressaoRegular, letra);
        }

        return string;
    }

    changeInput = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }

    search = async (e) => {
        e.preventDefault();
        if (this.state.searchValue && this.state.searchValue !== "") {
            const response = await this.removerAcentos(this.state.searchValue);
            this.props.history.push("/buscas/" + response);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className={this.state.toggleMenu}>
                <div className={styles.mobile_menu} onClick={(e) => this.toggleMenu(e)}>
                    <Menu right isOpen={this.state.isOpen}>
                        <ul>
                            {/*<li onClick={() => this.props.changeLang()}>
                                {/*<p className={styles.idioma}>{this.props.lang}</p>*
                                <NavLink className={styles.idioma} to='/'>{this.props.lang}</NavLink>
                            </li>*/}
                            <li onClick={(e) => this.toggleMenuClick(e)} className={styles.buscali}>
                                <Form>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="search" placeholder="Buscar" className={styles.search_header} value={this.state.searchValue} onChange={(e) => this.changeInput(e)} />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" onClick={(e) => this.search(e)}>
                                        <img src={iconsearch} alt="Busca" />
                                    </Button>
                                </Form>
                            </li>
                            <li onClick={(e) => this.toggleMenuClick(e)}><NavLink activeClassName='menu-link-active' to='/filmes'>{t('header.filmes', null, localStorage.getItem("lang"))}</NavLink></li>
                            <li onClick={(e) => this.toggleMenuClick(e)}><NavLink activeClassName='menu-link-active' to='/sobre'>{t('header.sobre', null, localStorage.getItem("lang"))}</NavLink></li>
                            <li onClick={(e) => this.toggleMenuClick(e)}><NavLink activeClassName='menu-link-active' to='/blog'>{t('header.blog', null, localStorage.getItem("lang"))}</NavLink></li>
                            <li onClick={(e) => this.toggleMenuClick(e)}><NavLink activeClassName='menu-link-active' to='/contato'>{t('contato', null, localStorage.getItem("lang"))}</NavLink></li>
                            {/* <li onClick={(e) => this.toggleMenuClick(e)}><NavLink activeClassName='menu-link-active' to='/loja'>{t('header.loja', null, localStorage.getItem("lang"))}</NavLink></li> */}
                            <li onClick={(e) => this.toggleMenuClick(e)} className={styles.guara}><a href="https://www.universoguara.com.br/" target="_blank" rel="noopener noreferrer"><img src={guara} alt="Guará" /></a></li>
                        </ul>
                    </Menu>
                </div>

                <header className={styles.header}>
                    <div className={styles.content_pd}>
                        <Container fluid className={styles.content_dt}>
                            <Row align="center">
                                <Col sm={4}>
                                    <NavLink className={styles.logo_app} to='/' exact >
                                        <img src={logodt} alt="DT - Filmes" />
                                    </NavLink>
                                </Col>
                                <Col sm={8}>
                                    <ul className={styles.m_desktop}>
                                        {/*<li onClick={() => this.props.changeLang()}>
                                            {/*<p className={styles.idioma}>{this.props.lang}</p>
                                            <NavLink className={styles.idioma} to='/'>{this.props.lang}</NavLink>
                                        </li>*/}
                                        <li><NavLink activeClassName='menu-link-active' to='/filmes'>{t('header.filmes', null, localStorage.getItem("lang"))}</NavLink></li>
                                        <li><NavLink activeClassName='menu-link-active' to='/sobre'>{t('header.sobre', null, localStorage.getItem("lang"))}</NavLink></li>
                                        <li><NavLink activeClassName='menu-link-active' to='/blog'>{t('header.blog', null, localStorage.getItem("lang"))}</NavLink></li>
                                        <li><NavLink activeClassName='menu-link-active' to='/contato'>{t('contato', null, localStorage.getItem("lang"))}</NavLink></li>
                                        {/* <li><NavLink activeClassName='menu-link-active' to='/loja'>{t('header.loja', null, localStorage.getItem("lang"))}</NavLink></li> */}
                                        {/* <li className={styles.assine}>
                                            <ButtonToolbar>
                                                <Button
                                                    variant="primary"
                                                    onClick={this.props.modalClickShow}
                                                >
                                                    {t('header.assine', null, localStorage.getItem("lang"))}
                                                </Button>

                                                <ModalNews
                                                    show={this.props.modalShow}
                                                    onHide={this.props.modalClickClose}
                                                />
                                            </ButtonToolbar>
                                        </li> */}
                                        <li className={styles.guara}><a href="https://www.universoguara.com.br/" target="_blank" rel="noopener noreferrer"><img src={guara} alt="Guará" /></a></li>
                                        <li className={styles.buscali_d}>
                                            <Form>
                                                <Form.Group controlId="formBasicEmail" className={styles.search_dt2}>
                                                    <Form.Control type="search" placeholder="" className={styles.search_header_d} value={this.state.searchValue} onChange={(e) => this.changeInput(e)} />
                                                    <Button variant="primary" type="submit" onClick={(e) => this.search(e)}>
                                                        <img src={iconsearch} alt="Busca" />
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </header>
            </div>
        );
    }
}

export default translate(withRouter(Header));