import React from 'react';
import styles from './Blog.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { Link } from 'react-router-dom';
import { uploadPath } from '../../services/api';

import {
  translate,
} from 'react-switch-lang';


function Blog(props) {
  
  const generatePost = (props) =>  {
    
    if(props.blog.data && !!props.blog.data.content) {
      
      return props.blog.data.content.files.map(post => {
        const { t } = props;
        return(
          
          <Link key={post.slug} to={"/blog-single/"+post.slug} className={styles.box_card}>
            <Row>
              <div>
                <div className={styles.col_um}>
                  <div className={styles.img_desktop}>
                    <div className={styles.imgbox} style={{backgroundImage: "url(" + uploadPath + post.link + ")"}}></div>
                  </div>
                  <Figure className={styles.o_hidden_img}>
                    <Figure.Image
                      width={1360}
                      height={770}
                      alt="Bruno Wainer aposta em pré-licenciamento: 'Hoje durmo tranquilo'"
                      src={uploadPath + post.link}
                    />
                  </Figure>
                </div>
                <div className={styles.col_dois}>
                  <span className={styles.date_post}>{post.created_at}</span>
                  <h2 className={styles.title_post}>
                    <span>{post.manchete} </span>
                  </h2>
                  <p className={styles.description_post}>{post.description}</p>
                  <span className={styles.autor_post}>{t('blog.por', null, localStorage.getItem("lang"))}: {post.publicated}</span>
                  <button className={styles.btn_black}>{t('blog.leia', null, localStorage.getItem("lang"))} <span>+</span></button>
                </div>
              </div>
            </Row>
          </Link>
        )
      })
    }
  }

  return (
   <section className={styles.content_dt}>
      <div className={styles.content_dtfilmes}>
        <Container fluid>
          <Row>
            <Col md="12">
              <div className={styles.wrapper}>
                {generatePost(props)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}



export default translate(Blog);