import React, { Component } from 'react';
import styles from './Footer.module.scss';
import {NavLink} from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';

import api from '../../services/api';

// Translation Higher Order Component
import {
    translate,
  } from 'react-switch-lang';

class Footer extends Component {

    state = {
        email: "",
        success: "",
        errors: "",
      }
    
    onChangeInput(e) {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    async sendNews(e) {
        e.preventDefault();
        const { email } = this.state;

        const data = {
            email,
            'type': 2
        }

        await this.setState({ errors: "", success: "" });

        await api.post('/contacts/store', data)
        .then(() => {
            this.setState({ success: this.props.t('sucesso.mensagemCadastro', null, localStorage.getItem("lang")) })
        })
        .catch(errors => {
            this.setState({ errors: this.props.t('errors.emailInvalido', null, localStorage.getItem("lang")) })
        })
    }

    render() {
        const { t } = this.props;
        return(
            <footer className={styles.footer_site}>
                <div className={styles.content_pd}>
                    <Container fluid>
                        <Row align="center">
                            <Col md={12}>
                                <div className={styles.warpper}>
                                    <Row align="end">
                                        <Col md={4}>
                                            <ul className={styles.ul_links}>
                                                <li><NavLink to='/contato'><i className="fal fa-arrow-up"></i> {t('footer.contato', null, localStorage.getItem("lang"))}</NavLink></li>
                                                <li><NavLink to='/politica-de-privacidade'><i className="fal fa-arrow-up"></i> {t('footer.politica', null, localStorage.getItem("lang"))}</NavLink></li>
                                            </ul>
                                        </Col>
                                        <Col md={4}>
                                            <ul className={styles.ul_rs}>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dtfilmes/" alt="instagram"><i className="fab fa-instagram"></i></a></li>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/downtownfilmesbr/featured" alt="youtube"><i className="fab fa-youtube"></i></a></li>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/dtfilmesbr/" alt="facebbook"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/dtfilmes" alt="twitter"><i className="fab fa-twitter"></i></a></li>
                                                <li><a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/user/v0qnza7dl3gvzktt9qsqih8ft" alt="spotify" className={styles.spotify_btn}><i className="fab fa-spotify"></i></a></li>
                                            </ul>
                                        </Col>
                                        <Col md={4} className={styles.ultcol}>
                                            <p>{t('footer.text1', null, localStorage.getItem("lang"))} <strong>DT FILMES</strong>?</p>
                                            <input value={this.state.email} onChange={e => this.onChangeInput(e)} type="email" name="email" id="email" placeholder={t('footer.text2', null, localStorage.getItem("lang"))}/>
                                            <button onClick={e => this.sendNews(e)}>{t('footer.enviar', null, localStorage.getItem("lang"))}</button>
                                            <div style={{marginTop: 60}}>
                                                {this.state.errors &&
                                                    <div className="alert alert-danger">
                                                        {this.state.errors}
                                                    </div>
                                                }

                                                {this.state.success &&
                                                    <div className="alert alert-success">
                                                        {this.state.success}
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        )
    }
};

Footer.propTypes = {

};

export default translate(Footer);