import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppContent from "./components/app_content/AppContent";
import './assets/css/App.css';

export default class App extends Component {

  constructor(props) {//statefull é o componente que manipula estado.

    super(props);

      this.state = {};

  }//todos os componentes abaixo deles são componentes stateless.

  render() {
    return(
        <Router>
          <AppContent {...this.state} />
        </Router>
    );
  }
}