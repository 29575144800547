import React from 'react';
import styles from './BlogSingle.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { Link } from 'react-router-dom';
import { uploadPath } from '../../services/api';
import { Markup } from 'interweave';

import {
    translate,
  } from 'react-switch-lang';

function BlogSingle(props) {
    const { t } = props;
    const blogs = (props) => {

        if(props.blog.data){
            const blog = props.blog.data.content.files.filter(blog => blog.slug === props.props.match.params.slug);
            return (
                <div className={styles.content_dtfilmes}>
                    <div className={styles.wrapper}>
                        <Container fluid>
                            <Row className={styles.mabottom}>
                                <Col xs={6}>
                                    <span className={styles.date_post}>{blog[0].created_at}</span>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <span className={styles.date_post}>{t('blog.compartilhar', null, localStorage.getItem("lang"))}</span>
                                    <ul className={styles.listshared}>
                                        <li><Link to="/" className={styles.email}><i className="fal fa-envelope"></i></Link> </li>
                                        <li><Link to="/" className={styles.faceb}><i className="fab fa-facebook-f"></i></Link> </li>
                                        <li><Link to="/" className={styles.twitter}><i className="fab fa-twitter"></i></Link> </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="align-items-end">
                                <Col md={4}>
                                    <h1 className={styles.title_post}>
                                        <span>{blog[0].manchete}</span>
                                    </h1>
                                    <p className={styles.description_post}>{blog[0].description}</p>
                                    <span className={styles.autor_post}>{blog[0].publicated}</span>
                                </Col>
                                <Col md={8}>
                                    <Figure className={styles.img_blog}>
                                        <Figure.Image
                                            alt="imagem post"
                                            src={uploadPath + blog[0].link}
                                        />
                                    </Figure>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}></Col>
                                <Col md={8}>
                                    <article className={styles.post_content}>
                                        <Markup content={blog[0].body} />

                                        <div className={styles.fonte}>
                                            <span>{t('blog.fonte', null, localStorage.getItem("lang"))}:</span> <a href={blog[0].font} target="_blank" rel="noopener noreferrer" >{blog[0].font}</a>
                                        </div>
                                    </article>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )
        }
    }
    return (

        <section className={styles.content_dt}>
            {blogs(props)}
        </section>

    );
}

export default translate(BlogSingle)