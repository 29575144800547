import React from 'react';
import styles from './Buscas.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import api from '../../services/api';

import {
  translate,
} from 'react-switch-lang';

class Buscas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: []
    }
  }

  UNSAFE_componentWillMount() {
    this.search(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.search(nextProps);
  }

  async search(props) {
    const s = props.match.params.s;

    const response = await api.get('/search/' + s + '/' + localStorage.getItem("lang"));

    this.setState({
      value: response["data"]
    })
  }

  render() {
    const { t } = this.props;
    return (
      <section className={styles.content_dt}>
          <div className={styles.content_dtfilmes}>
            <Container fluid>
              <Row className="justify-content-center">
                <Col md={10} lg={8}>
                  <h1>{t('busca.resultado', null, localStorage.getItem("lang"))}</h1>

                  <ul className={styles.lista_busca}>
                    {this.state.value.map(result => {
                      return <li key={result.id}><a href={"/filmes-detalhes/"+result.slug} target="" rel="noopener noreferrer" alt="">{result.name}</a></li>
                    })}
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
      </section>
    );
  }
} 

export default translate(Buscas);