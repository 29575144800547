import React from 'react';
import styles from './PoliticaPrivacidade.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Markup } from 'interweave';

import api from '../../services/api';

import {
  translate,
} from 'react-switch-lang';

class PoliticaPrivacidade extends React.Component {

  state = {
    privacy: []
  };
  
  async componentWillMount() {
    const privacy = await api.get('/pages/show/privacy/'+localStorage.getItem("lang"));
    this.setState({
      privacy
    })
  }

  generateLI = () => {
    if(this.state.privacy.data) {
      return this.state.privacy.data.content.files.map( (texto,i) => {
        return (
          <Markup key={i} content={texto} />
        )
      })
    }
  }

  render() {
    const { t } = this.props;
    return (
    <section className={styles.content_dt}>
        <div className={styles.content_dtfilmes}>
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className={styles.wrapper}>
                  <h1>{t('footer.politica', null, localStorage.getItem("lang"))}</h1>

                  <Row>
                    <Col md={12}>
                      <div className={styles.box_politica}>
                        {this.generateLI(this.props)}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default translate(PoliticaPrivacidade);