import React from 'react';
import styles from './Sobre.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { NavLink } from "react-router-dom";
import { Markup } from 'interweave';

import {
  translate,
} from 'react-switch-lang';

import { uploadPath } from '../../services/api';

function Sobre(props) {

  const title_link = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return (
          <h2 key={sobre.link_title}>{<Markup content={sobre.link_title} />}</h2>
        )
      })
    }

  }
  const title = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return (
          <Markup key={sobre.title} content={sobre.title} />
        )
      })
    }

  }
  const description = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return (
          <Markup key={sobre.description} content={sobre.description} />
        )
      })
    }

  }
  const successText = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return (
          <Markup key={sobre.successText} content={sobre.successText} />
        )
      })
    }
  }
  const linkImg = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map((sobre, i) => {
        return (
          <Container key={i} fluid className={styles.destaque} style={{ backgroundImage: "url(" + uploadPath + sobre.link + ")" }}>
            <Row align="end" style={{ height: '100%' }}>
              <Col md={12} style={{ width: '100%' }}>
                <div className={styles.content_dt}>
                  <div className={styles.content_pd}>
                    <div className={styles.destexto}>
                      {title_link(props)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )
      })
    }
  }

  const movies = props => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return sobre.successMovies.map(movie => {
          return <li key={movie.value}><NavLink to={'/filmes-detalhes/' + movie.value}>{
            (window.innerWidth > 997) ?
              movie.label :
              (movie.label.length > 25) ? movie.label.substring(0, 25) + "..." : movie.label
          }</NavLink></li>
        })
      });
    };
  }

  const marketPosition = (props) => {
    if (props.sobre.data && !!props.sobre.data.content) {
      return props.sobre.data.content.files.map(sobre => {
        return (
          <>
            <Markup key={sobre.market_position} content={sobre.market_position} />
            <a className={styles.btn_black} target="blank" href={uploadPath + sobre.file_market_position}>Acesse</a>
          </>
        )
      })
    }
  }

  const { t } = props;

  return (

    <section className={styles.content}>
      <h1 className={styles.noth1}>{t('header.sobre', null, localStorage.getItem("lang"))}</h1>
      {linkImg(props)}

      <div className={styles.content_pd}>
        <Container fluid className={styles.content_dt_2}>
          <Row>
            <Col lg={4} md={6}>
              {title(props)}
            </Col>
            <Col lg={4} md={6}>
              {description(props)}
              <span className={styles.divisor}></span>
              {successText(props)}
              <ul>
                {movies(props)}
              </ul>
            </Col>
            <Col lg={4} md={6}>
              <div className={styles.disc_li}>
                {marketPosition(props)}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>

  );
}

export default translate(Sobre);