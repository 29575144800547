import React from 'react';
import styles from './Loja.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  translate,
} from 'react-switch-lang';

function Loja(props) {
    const { t } = props;
    return (
        <section className={styles.content_dt}>
           <div className={styles.content_dtfilmes}>
             <Container fluid>
               <Row>
                 <Col md={12}>
                    <h1>{t('header.loja', null, localStorage.getItem("lang"))}</h1> <p>- {t('mensagem.emBreve', null, localStorage.getItem("lang"))}</p>
                 </Col>
               </Row>
             </Container>
           </div>
         </section>
    );
}

export default translate(Loja);