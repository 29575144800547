import React from 'react';
import styles from './Filmes.module.scss';
import Masonry from 'react-masonry-component';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

import { uploadPath } from '../../services/api';

const masonryOptions = {
  transitionDuration: 0
};

class Filmes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moviePage: [],
      pagesPerPage: 12,
      pages: 0,
      currentPage: 1,
    };
  }

  async componentDidMount() {
    try {
      const allPages = this.props.movies.data;
      const pagesPerPage = this.state.pagesPerPage;
      const pages = parseInt(allPages / pagesPerPage);
      const moviePage = allPages.slice(0, pagesPerPage);

      this.setState({
        moviePage,
        allPages,
        pages,
      });
    } catch (err) {
      this.setState({
        error: 'Nós encontramos um erro ao pegar os posts.',
      });
    }
  }

  generateLI = (props) => {
    if (props.movies.data) {
      return this.state.moviePage.slice(3, 200).map((movie) => (
        <li key={movie.slug}>
          <a href={'filmes-detalhes/' + movie.slug} rel="noopener noreferrer" alt={movie.name}>
            {movie.name} <span className={styles.ano}>{movie.year}</span>
          </a>
        </li>
      ));
    }
  };


  generateChildElement = (props) => {
    if (props.movies && props.movies.data) {
      return props.movies.data.slice(0, 3).map((movie) => {
        /* const linkCard = movie.link_card;
        const link = linkCard && linkCard[0] && linkCard[0].link; */

        return (
          <li key={movie.id} /* className={styles.fadeInUp} */>
            <NavLink
              to={'/filmes-detalhes/' + movie['slug']}
              className={styles.box_cards}
              style={{ backgroundImage: `url(${uploadPath + "upload/" + movie.link_imagem_relacionada})` }}
            >
              <Image src={uploadPath + "upload/" + movie.link_imagem_relacionada} className={styles.img_mobile} />
              <div className={styles.title_filme}>
                <h2>{movie.name}</h2>
              </div>
            </NavLink>
          </li>
        );
      });
    } else {
      return null;
    }
  };

  changePage = () => {
    const pagesPerPage = this.state.pagesPerPage + 6;
    const moviePage = this.state.allPages.slice(0, pagesPerPage);

    this.setState({
      moviePage,
      pagesPerPage,
    });
  };

  render() {
    return (
      <section className={styles.gridflilmes}>
        <div className={styles.gridum}>
          <Masonry
            className={styles.filmes_class}
            elementType={'ul'}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
          >
            {this.generateChildElement(this.props)}
          </Masonry>
        </div>

        <div className={styles.griddois}>
          <ul>{this.generateLI(this.props)}</ul>
          <NavLink to='/filmes' onClick={() => this.changePage()} className={styles.btn_vermais}>
            <span className={styles.iconmais}>+ títulos</span>
          </NavLink>
        </div>
      </section>
    );
  }
}

export default Filmes;
