import React from 'react';
import styles from './FilmesDetalhes.module.scss';
import '../../assets/css/classification.css';
import { Container, Row, Col } from 'react-grid-system';

import { uploadPath } from '../../services/api';

// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';

function FilmesDetalhes(props) {

  function hidden() {

    document.getElementById("justImg").style.visibility = 'hidden';
    document.getElementById("justImg").style.height = '1px';
    document.getElementById("video").style.height = '660px';
    document.getElementById("video").src += '?&autoplay=1';

  }

  function stripTags(str) {
    return str.replace(/<[^>]*>|&nbsp;/g, '');
  }

  const movies = (props) => {
    if (props.movies.data) {

      const movie = props.movies.data.filter(movie => movie.slug === props.props.match.params.slug);
      const { t } = props;
      var summary = stripTags(movie[0].summary);
      return (
        <section>
          <div className={styles.img_video}>
            <div onClick={() => hidden()} className={styles.dest_video} id="justImg">
              <div className={styles.imgDest} style={{ backgroundImage: "url(" + uploadPath + movie[0].files[1].links[0].link + ")" }}></div>
            </div>
            <div className="video">
              <iframe id="video" width="100%" height="0" title="filme" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen src={'https://www.youtube.com/embed/' + movie[0].link_video} className={styles.iframe_vd}></iframe>
            </div>
          </div>
          <div className={styles.content_dtfilmes}>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <header className={styles.header_content}>
                    <h1>
                      {movie[0].name}
                      <a href={"/buscas/classificationigual" + movie[0].classification} className={"classification_" + movie[0].classification} alt="Classificação Indicativa">{movie[0].classification}</a>
                    </h1>
                    <div className={styles.box_descri}>
                      <Row>
                        <Col md={4}>
                          <span className={styles.title_dt}>{t('filmesdetalhe.direcao', null, localStorage.getItem("lang"))}</span>
                          <span className={styles.name_dt}>{movie[0].direction}</span>
                        </Col>
                        <Col md={4}>
                          <span className={styles.title_dt}>{t('filmesdetalhe.producao', null, localStorage.getItem("lang"))}</span>
                          <span className={styles.name_dt}>{movie[0].production}</span>
                        </Col>
                        <Col md={4}>
                          {/* <a href="/" target="_blank" rel="noopener noreferrer" alt="Assista aqui" className={styles.btn_back}>Assista aqui</a> */}
                        </Col>
                        <Col md={4}>
                          <span className={styles.title_dt}>{t('filmesdetalhe.atores', null, localStorage.getItem("lang"))}</span>
                          <span className={styles.name_dt}>{movie[0].actors}<br></br></span>
                        </Col>
                        <Col md={4}>
                          <span className={styles.title_dt}>{t('filmesdetalhe.genero', null, localStorage.getItem("lang"))}</span>
                          <span className={styles.name_dt}>{movie[0].genre}</span>
                        </Col>
                        <Col md={4}>
                          <span className={styles.title_dt}>{t('filmesdetalhe.ano', null, localStorage.getItem("lang"))}</span>
                          <span className={styles.ano_dt}>{movie[0].year}</span>
                        </Col>
                      </Row>
                    </div>
                  </header>

                  <article className={styles.container_dt}>
                    <Row>
                      <Col md={4}>
                        <figure className={styles.poster}>
                          <img src={uploadPath + movie[0].files[2].links[0].link} alt="DT - Filmes" />
                        </figure>
                      </Col>
                      <Col md={8}>
                        <div>
                          <p>{summary}</p>
                          <div className={styles.links}></div>
                        </div>
                      </Col>
                      {/*<Col md={12}>
                         <div className={styles.content_relacionados}>
                          <h3>{t('filmesdetalhe.titulos', null, localStorage.getItem("lang"))}</h3>

                          <Row>
                            {movie.related_movies.map(related => {
                              return (
                                <Col key={related.id} md={4}>
                                  <a href={"/filmes-detalhes/" + related.slug} rel="noopener noreferrer" alt="" className={styles.box_cards}>
                                    <div className={styles.title_filme}>
                                      <h2>{related.name}</h2>
                                    </div>
                                    {related.link_imagem_relacionada &&
                                      <img src={uploadPath + "upload/" + related.link_imagem_relacionada} alt="" />
                                    }
                                  </a>
                                </Col>
                              )
                            })}

                            <Col md={12}>
                              <NavLink to='/filmes' className={styles.btn_vermais}><span className={styles.iconmais}>+</span> {t('filmesdetalhe.maistitulos', null, localStorage.getItem("lang"))}</NavLink>
                            </Col>
                          </Row>
                        </div> 
                      </Col>*/}
                    </Row>
                  </article>
                </Col>
                {/* <div className={styles.links}>
                  {movie[0].social.map(social => {
                    if (social["name"] === "facebook") {
                      return <a href={social["links"][0]["link"]} target="_blank" rel="noopener noreferrer" alt="Facebook" className={styles.btnlinks}><i className="fab fa-facebook-f"></i></a>
                    } else if (social["name"] === "twitter") {
                      return <a href={social["links"][0]["link"]} target="_blank" rel="noopener noreferrer" alt="Twitter" className={styles.btnlinks}><i className="fab fa-twitter"></i></a>
                    } else {
                      return <a href={social["links"][0]["link"]} target="_blank" rel="noopener noreferrer" alt="Instagram" className={styles.btnlinks}><i className="fab fa-instagram"></i></a>
                    }
                  })}
                  <div>
                    <a href="/" target="_blank" rel="noopener noreferrer" alt="PDF" className={styles.btnpdf}>PDF</a>
                  </div>
                </div> */}
              </Row>
            </Container>
          </div>
        </section>
      )
    }
  }
  return (
    <section className={styles.content_dt}>
      {movies(props)}
    </section>
  );
}
export default translate(FilmesDetalhes);