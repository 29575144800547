import React from 'react';
import styles from './Guara.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Guara() {

    return (
        <section className={styles.content_dt}>
           <div className={styles.content_dtfilmes}>
             <Container fluid>
               <Row>
                 <Col md={12}>
                    <h1>Guará</h1> <p>- Em breve</p>
                 </Col>
               </Row>
             </Container>
           </div>
         </section>
    );
}
