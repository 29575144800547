import React from 'react';
import styles from './Home.module.scss';
import './carousel.css';
import '../../assets/css/classification.css';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from 'react-router-dom';

import { uploadPath } from '../../services/api';

export default function Home(data) {

    const generateCarousel = (data) => {
        if (data.carousel && !!data.carousel.content) {
            return data.carousel.content.files.map(caroselItem => {
                const classification = getClassification(caroselItem);
                return (
                    <Carousel.Item key={caroselItem.name}>
                        <NavLink to={caroselItem.link_movie} key={caroselItem.name} className={styles.box_carousel} style={{ backgroundImage: "url(" + uploadPath + caroselItem.link + ")" }}>
                            <Carousel.Caption className={styles.box_caption}>
                                <div className={styles.box_text}>
                                    <h3>
                                        {caroselItem.name}
                                        <a href={"/buscas/classificationigual"+classification} className={"classification_"+classification} alt="Classificação Indicativa">{classification}</a>
                                    </h3>
                                </div>
                            </Carousel.Caption>
                        </NavLink>
                    </Carousel.Item>
                );
            });
        }
    };
    const getClassification = (caroselItem) => {
        if(!data || !data.carousel || !data.movies) return "";
        const movie = data.movies.data.find(movie => (movie.name === caroselItem.name ))
        return movie.classification;
    }
    return (
        <section className={styles.content_home}>
            <h1 className={styles.noth1}>DT - Filmes</h1>
            <div>
                <Carousel className={!!data.carousel ?
                    data.carousel.content.files.length > 1 ?
                        "box_carousel" :
                        "box_carousel disable" : ""}>
                    {generateCarousel(data)}
                </Carousel>
            </div>
        </section>
    );
}
